import React from "react";
import { Grid } from "@mui/material";
import { ServicesCard } from "@features/services/services-card";
import appInfo from "@data/app-info.json";

export function Services() {
  return (
    <>
      <Grid container spacing={3}>
        {appInfo.menuItems[1].subMenu.map((item: Services.ItemType) => (
          <ServicesCard key={item.id} data={item} />
        ))}
      </Grid>
    </>
  );
}
