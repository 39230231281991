import React from "react";
import { Box, Grid, Container, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { InfoTabs, InfoTabsMobile } from "@components/info-tabs";
import { Advantages } from "@components/advantages";
import { ImageViewer } from "@components/image-viewer";
// import { PriceTable } from "@components/price";
import appInfo from "@data/app-info.json";
import infoTabs from "@data/info-tabs.json";
// import price from "@data/price.json";
import portfolio from "@data/portfolio.json";

export function BathhousePage() {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: { sx: "15vh", lg: "23vh" },
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    topContainer: {
      pt: { sm: "30px", lg: "40px" },
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginTop: { sx: "10px", lg: "20px" },
    },
    contentContainer: {
      padding: { xs: "30px 10px", md: "60px 10px" },
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #323232, transparent)",
      pb: "10px",
    },
    devider: {
      margin: "50px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
      marginBottom: "20px",
    },
    advantageWrapper: {
      marginTop: "30px",
    },
    topPageTitle: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textAlign: "center",
      marginBottom: "30px",
    },
    topPageBox: {
      padding: { xs: 0, md: "50px 10px" },
    },
    topPageImgBox: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    topPageImg: {
      width: "100%",
      height: { xs: "300px", md: "100%" },
      background:
        "url(/static/services/services-icon-10.jpg) no-repeat center center",
      backgroundSize: "cover",
    },
    topPageContent: { marginBottom: "20px" },
    topPageText: { textAlign: "justify", marginBottom: "10px" },
    infoTabsBox: {
      display: { xs: "none", sm: "block" },
      width: { xs: 0, sm: "100%" },
      height: { xs: 0, sm: "100%" },
    },
    infoTabsMobileBox: {
      display: { xs: "block", sm: "none" },
      width: { xs: "100%", sm: 0 },
      height: { xs: "100%", sm: 0 },
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="body1" gutterBottom sx={styles.title}>
              Баня, сайна, хамам в Севастополе и Крыму
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Баня, сауна, хахам
        </Typography>
        <Grid container spacing={2} sx={styles.topPageBox}>
          <Grid item xs={12} md={6} sx={styles.topPageImgBox}>
            <Grid sx={styles.topPageImg}></Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.topPageContent}>
            <Typography variant="body1" sx={styles.topPageText}>
              Существует множества различных видов бань. Они отличаются между собой сочетанием температуры и влажности,
              микроклиматом. Это самые информативные показатели. Основные виды бань:
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Паровая баня, или турецкий хамам. Температура 40-45°, влажность
              90-100%.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Паровая сауна, или русская баня. Температура 45-70°, влажность
              40-65%.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Суховоздушная сауна, или финская сауна. Температура 70-110°,
              влажность 8-20%.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              В разных видах бань по-своему осуществляются обогрев и подача
              пара, есть свои особенности, плюсы и минусы.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Баня, Сауна, Хамам в Севастополе и Крыму
        </Typography>
        <Box sx={styles.infoTabsBox}>
          <InfoTabs data={infoTabs.items[8]} />
        </Box>
        <Box sx={styles.infoTabsMobileBox}>
          <InfoTabsMobile data={infoTabs.items[8]} />
        </Box>
        {/* <PriceTable data={price.items[0]} /> */}
        <ImageViewer portfolio={portfolio[0]} />
        <Grid sx={styles.contentContainer}>
          <Typography variant="body1" sx={styles.advantagesTitle}>
            Нас рекомендуют потому что "{appInfo.companyName}" это:
          </Typography>
          <Advantages />
        </Grid>
      </Container>
    </>
  );
}
