import React from "react";
import { Box, Grid, Container, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { InfoTabs, InfoTabsMobile } from "@components/info-tabs";
import { Advantages } from "@components/advantages";
import { ImageViewer } from "@components/image-viewer";
import appInfo from "@data/app-info.json";
import infoTabs from "@data/info-tabs.json";
import portfolio from "@data/portfolio.json";

export function SmartHomePage() {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: { sx: "15vh", lg: "23vh" },
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    topContainer: {
      pt: { sm: "30px", lg: "40px" },
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginTop: { sx: "10px", lg: "20px" },
    },
    contentContainer: {
      padding: { xs: "30px 10px", md: "60px 10px" },
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #323232, transparent)",
      pb: "10px",
    },
    devider: {
      margin: "50px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
      marginBottom: "20px",
    },
    advantageWrapper: {
      marginTop: "30px",
    },
    topPageTitle: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textAlign: "center",
      marginBottom: "30px",
    },
    topPageBox: {
      padding: { xs: 0, md: "50px 10px" },
    },
    topPageImgBox: {
      paddingTop: "10px",
      paddingBottom: "10px",
      width: "100%",
      minHeight: "200px",
    },
    video: {
      objectFit: "cover !important",
    },
    topPageImg: {
      width: "100%",
      height: { xs: "300px", md: "100%" },
      background:
        "url(/static/services/services-icon-5.jpg) no-repeat center center",
      backgroundSize: "cover",
    },
    topPageContent: { marginBottom: "20px" },
    topPageText: { textAlign: "justify", marginBottom: "10px" },
    infoTabsBox: {
      display: { xs: "none", sm: "block" },
      width: { xs: 0, sm: "100%" },
      height: { xs: 0, sm: "100%" },
    },
    infoTabsMobileBox: {
      display: { xs: "block", sm: "none" },
      width: { xs: "100%", sm: 0 },
      height: { xs: "100%", sm: 0 },
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="body1" gutterBottom sx={styles.title}>
              Системы умный дом в Севастополе и Крыму
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Умный дом в Севастополе и Крыму
        </Typography>
        <Grid container spacing={2} sx={styles.topPageBox}>
          <Grid item xs={12} md={6} sx={styles.topPageImgBox}>
            <video
              autoPlay
              loop
              controls
              muted
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover" }}
              poster="/static/video/poster.jpg"
            >
              <source
                src="/static/video/smarthome.mp4"
                type="video/mp4"
              />
            </video>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.topPageContent}>
            <Typography variant="body1" sx={styles.topPageText}>
              Умный дом - это система интеллектуального управления домом,
              которая делает вашу жизнь намного проще и комфортнее. Все что
              нужно - сценарии один раз, и умный дом все будет делать за вас!
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Подъезжая к дому, вы имеет полны доступ ко всем системам дома,
              можете открыть ворота гаража или дверь квартиры, включить подогрев
              полов, включить освещение. Умный дом также может сделать это
              автоматически, определив вашу геолокацию. Уходя из дома, дверь
              также закроется автоматически, выключится отопление и освещение.
            </Typography>
            <Typography variant="body1" sx={styles.topPageText}>
              Находясь за пределами дома, например на работе, вы можете
              присматривать за ребенком или отдавать команды домашним питомцам
              через встроенный в видеокамеру голосовой модуль. Умная камера
              распознает движения и незнакомые лица у вас в доме или на крыльце,
              прислав вам уведомление на телефон.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h3" sx={styles.topPageTitle}>
          Монтаж систем умного дома в Севастополе и Крыму
        </Typography>
        <Box sx={styles.infoTabsBox}>
          <InfoTabs data={infoTabs.items[6]} />
        </Box>
        <Box sx={styles.infoTabsMobileBox}>
          <InfoTabsMobile data={infoTabs.items[6]} />
        </Box>
        <ImageViewer portfolio={portfolio[4]} />
        <Grid sx={styles.contentContainer}>
          <Typography variant="body1" sx={styles.advantagesTitle}>
            Нас рекомендуют потому что "{appInfo.companyName}" это:
          </Typography>
          <Advantages />
        </Grid>
      </Container>
    </>
  );
}
