import React from "react";
import {
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { CarouselTop } from "@components/carousel";
import { Services } from "@features/services";
import { Advantages } from "@components/advantages";
import appInfo from "@data/app-info.json";
import { ContactsInfo } from "@features/contacts-info";

export function HomePage() {
  const theme = useTheme();

  const styles = {
    wrapper: {},
    contentContainer: {
      padding: "60px 10px",
    },
    devider: {
      margin: "20px auto",
    },
    advantagesTitle: {
      fontSize: theme.typography.h3,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: "center",
    },
  };

  return (
    <>
      <Grid sx={styles.wrapper}>
        <CarouselTop />
      </Grid>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="body1" sx={styles.advantagesTitle}>
          Основные виды работ
        </Typography>
        <Grid sx={styles.contentContainer}>
          <Services />
        </Grid>
        <Divider sx={styles.devider} />
        <Typography variant="body1" sx={styles.advantagesTitle}>
          Нас рекомендуют потому что {appInfo.companyName} это:
        </Typography>
        <Grid sx={styles.contentContainer}>
          <Advantages />
        </Grid>
        <Divider sx={styles.devider} />
        <ContactsInfo />
      </Container>
    </>
  );
}
