import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import advantages from "@data/advantages.json";

export const Advantages = () => {
  const theme = useTheme();

  const styles = {
    card: {
      maxWidth: "100%",
    },
    cardInner: {
      display: {xs: 'flex', sm: 'block'}
    },
    imgBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      display: "flex",
      margin: "10px auto",
      maxWidth: 60,
      maxHeight: 60,
    },
    title: {
      fontSize: { xs: "0.9rem", sm: theme.typography.body1 },
      fontWeight: 700,
      minHeight: { xs: "0", sm: "40px", md: "65px" },
      textAlign: "center",
      paddingRight: {xs: 1, sm: 0}
    },
    subTitle: {
      minHeight: { xs: 0, sm: "100px", md: "120px" },
      color: theme.palette.text.primary,
    },
  };

  return (
    <Grid container spacing={3}>
      {advantages.items.map((item: any) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={item.id}>
          <Card sx={styles.card}>
            <Grid sx={styles.cardInner}>
              <Grid sx={styles.imgBox}>
                <Box
                  component="img"
                  style={styles.img}
                  src={`/static/icons/${item.img}`}
                  alt={item.alt}
                />
              </Grid>
              <Grid>
                <CardContent sx={{padding: {xs: 0, sm: 2}, paddingBottom: '0 !important'}}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={styles.title}
                  >
                    {item.title}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
