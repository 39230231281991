import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem, useTheme } from "@mui/material";
import "./HeaderMenuItems.css";
import { AttachMoney, ExpandMore, Home, Phone } from "@mui/icons-material";
import appInfo from "@data/app-info.json";

export function HeaderMenuItems(props: any) {
  const { handleClose, itemColor } = props;
  const theme = useTheme();

  const styles = {
    menuItem: {
      borderRadius: "6px",
      [theme.breakpoints.up("lg")]: {
        padding: "6px 10px",
      },
    },
  };

  return (
    <>
      {appInfo.menuItems.map((item: any) => (
        <MenuItem
          key={item.id}
          onClick={handleClose}
          disableRipple
          sx={styles.menuItem}
        >
          <NavLink
            to={item.path}
            className={({ isActive }) =>
              isActive
                ? "nav-link active-nav-link"
                : itemColor
                ? "nav-link mobile-nav-link"
                : "nav-link"
            }
          >
            {item.title === "Главная" ? (
              <Home />
            ) : item.title === "Услуги" ? (
              <AttachMoney />
            ) : (
              <Phone />
            )}
            {item.title}
            {item.subMenu.length ? <ExpandMore /> : null}
          </NavLink>
        </MenuItem>
      ))}
    </>
  );
}
