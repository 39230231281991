import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

export function ServicesCard(props: Services.DataTypes) {
  const { data } = props;
  const theme = useTheme();

  const styles = {
    card: { display: "flex" },
    link: { color: theme.palette.text.primary },
    cardIconBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "8px"
    },
    cardIconBorder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: { xs: "120px", sm: "140px", md: "170px" },
      height: { xs: "120px", sm: "140px", md: "170px" },
      borderRadius: "50%",
      boxShadow:
        "-10px -10px 15px rgba(255, 255, 255, 0.5), 10px 10px 15px rgba(0, 0, 0, 0.3), inset -10px -10px 15px rgba(255, 255, 255, 0.5), inset 10px 10px 15px rgba(0, 0, 0, 0.3)",
    },
    cardIcon: {
      display: "flex",
      width: { xs: "100px", sm: "120px", md: "150px" },
      height: { xs: "100px", sm: "120px", md: "150px" },
      borderRadius: "50%",
      objectFit: "cover",
    },
    cardTitle: {
      display: "flex",
      justifyContent: "center",
      fontSize: theme.typography.body1,
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  };

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card sx={styles.card} elevation={2}>
        <CardActionArea>
          <NavLink to={`/services${data.path}`}>
            <Grid sx={styles.cardIconBox}>
              <Grid sx={styles.cardIconBorder}>
                <Box
                  component="img"
                  src={`/static/services/thubnails/${data.img}`}
                  alt={data.title}
                  sx={styles.cardIcon}
                />
              </Grid>
            </Grid>
            <CardContent>
              <Typography variant="body1" sx={styles.cardTitle}>
                {data.title}
              </Typography>
            </CardContent>
          </NavLink>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
