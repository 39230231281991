export const telegramBot = async(props: any) => {
  const { customer } = props;

  const message = `Заявка на расчет:\n+${customer.customerPhone}\n${customer?.customerName}`

  return await fetch('https://195.66.87.139:3000/send-message', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      botId: 'umsstroy',
      message,
    }),
  });
}
