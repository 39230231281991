import React from "react";
import {
  Container,
  Grid,
  Box,
  styled,
  Typography,
  Link,
  // useTheme,
} from "@mui/material";
import { FooterMenuItems } from "@features/footer/footer-menu-items";
import { Phone } from "@mui/icons-material";
import { Messengers } from "@components/messengers";
import appInfo from "@data/app-info.json";

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.primary.contrastText,
  marginTop: "auto",
}));

export function Footer() {
  // const theme = useTheme();

  const styles = {
    phoneLink: {
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      marginTop: "10px",
    },
    footerText: {
      marginTop: "10px",
    },
    contentBox: {
      marginTop: {xs: 0, md: "65px"}
    },
  };

  return (
    <FooterBox px={{ xs: 3, sm: 5 }} py={{ xs: 3, sm: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="/logo512.png"
              alt="footer logo"
              sx={{ maxHeight: "80px" }}
            />
            <Typography variant="body1" sx={styles.footerText}>
              С большим удовольствием будем рады ответить на ваши вопросы и
              видеть вас в качестве наших клиентов или партнеров!
            </Typography>
            <Link
              type="tel"
              href={"tel:" + appInfo.contacts.messengers[0].phone}
              sx={styles.phoneLink}
            >
              <Phone sx={{ marginRight: "10px" }} />
              {appInfo.contacts.messengers[0].title}
            </Link>
            <Box marginTop={"15px"}>
              <Messengers />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={styles.contentBox}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom borderBottom={1}>
                  Информация
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>
                  <FooterMenuItems items={appInfo.menuItems} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom borderBottom={1}>
                  Услуги
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>
                  <FooterMenuItems items={appInfo.menuItems[1].subMenu} type={"services"} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          textAlign="center"
          pt={{ xs: 5, sm: 10 }}
          pb={{ xs: 5, sm: 0 }}
          sx={{ fontSize: { xs: 12, md: 14 } }}
        >
          &reg;{appInfo.companyName} - {new Date().getFullYear()}
        </Box>
      </Container>
    </FooterBox>
  );
}
