import React from "react";
import { NavLink } from "react-router-dom";
import "./FooterMenuItems.css";

export function FooterMenuItems(props: any) {
  const { items, type } = props;
  const menuItems = items.map((item: any) => {
    return (
      <NavLink
        key={item.id}
        to={type ? `/${type}${item.path}` : item.path}
        className={({ isActive }) =>
          isActive
            ? "footer-nav-link footer-nav-link-active"
            : "footer-nav-link"
        }
      >
        {item.title}
      </NavLink>
    );
  });

  return menuItems;
}
