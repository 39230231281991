import React, { SyntheticEvent, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`info-tabpanel-${index}`}
      aria-labelledby={`info-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingRight: 3, paddingLeft: 3 }}>{children}</Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `info-tab-${index}`,
    "aria-controls": `info-tabpanel-${index}`,
  };
}

type InfoTabPanelType = {
  id: number;
  info: InfoTabs.Info[];
  value: number;
};

function InfoTabPanel(props: InfoTabPanelType) {
  const { id, info, value } = props;
  return (
    <TabPanel value={value} index={id}>
      {info.map((type: InfoTabs.Info) => {
        return (
          <Typography variant="body1" key={type.id} textAlign="justify" sx={{marginBottom: "10px"}}>
            {type.text}
          </Typography>
        );
      })}
    </TabPanel>
  );
}

export function InfoTabs(props: InfoTabs.Data) {
  const { data } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const styles = {
    tabsBox: {
      flexGrow: 1,
      bgcolor: "background.paper",
      display: "flex",
    },
    tabs: {
      borderRight: 1,
      borderColor: "divider",
      maxWidth: "30vw",
      width: "100%",
    },
    tab: { borderBottom: "1px solid #cccccc" },
    tabPanelBox: {
      maxWidth: "70vw",
      width: "100%",
      padding: "5px",
    },
  };

  return (
    <Box sx={styles.tabsBox}>
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Info tabs"
        sx={styles.tabs}
      >
        {data.subtypes.map((item) => {
          return (
            <Tab
              label={item.title}
              {...a11yProps(item.id)}
              key={item.id}
              sx={styles.tab}
            />
          );
        })}
      </Tabs>
      <Box sx={styles.tabPanelBox}>
        {data.subtypes.map((item) => {
          return (
            <InfoTabPanel
              id={item.id}
              info={item.info}
              value={value}
              key={item.id}
            />
          );
        })}
      </Box>
    </Box>
  );
}
