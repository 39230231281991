import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
  img: any;
  type: string;
  height?: string | undefined;
  minHeight?: string | undefined;
  isContain?: boolean | undefined;
  lightBoxToggle?: () => void;
  works?: string;
};

export const LazyLoadImageBox = (props: Props) => {
  const { img, type, height, minHeight, isContain, lightBoxToggle, works } =
    props;

  return (
    <LazyLoadImage
      src={`/static/${type}${works ? `/${works}/` : "/"}${img.name}`}
      style={{
        objectFit: isContain ? "contain" : "cover",
        minHeight: minHeight ? minHeight : "100%",
        height: height ? height : "100%",
      }}
      height="100%"
      width="100%"
      effect="blur"
      placeholderSrc={`/static/${type}${works ? `/${works}/` : "/"}thubnails/${
        img.name
      }`}
      alt={img.alt}
      onClick={lightBoxToggle}
    />
  );
};
