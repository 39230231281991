import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import {
  lightBoxImgIdAction,
  lightBoxModalToggleAction,
  LightBoxSlider,
} from "@components/lightbox";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styles.css";
import { LazyLoadImageBox } from "@components/lazy-load";

type Props = {
  images: any[];
  type: string;
  height?: number;
  isLightBox: boolean;
  isThumbs?: boolean;
  works?: string;
  className: string;
};

export function Carousel(props: Props) {
  const { images, type, isLightBox, isThumbs, height, works, className } =
    props;
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const isOpen = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.isOpen
  );
  const dispatch = useDispatch();

  const lightBoxToggle = useCallback(
    (id: number) => {
      dispatch(lightBoxImgIdAction(id));
      dispatch(lightBoxModalToggleAction(!isOpen));
    },
    [dispatch, isOpen]
  );

  const breakpoints = {
    768: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 3,
    },
  };

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        breakpoints={breakpoints}
        height={height}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={className ?? "mySwiper2"}
      >
        {images.map((item: any) => {
          return (
            <SwiperSlide key={item.id}>
              <LazyLoadImageBox
                img={item}
                type={type}
                works={works}
                lightBoxToggle={
                  isLightBox ? () => lightBoxToggle(item.id) : undefined
                }
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {isThumbs && (
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={3}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {images.map((item: any) => {
            return (
              <SwiperSlide key={item.id}>
                <LazyLoadImageBox img={item} type={type} works={works} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <LightBoxSlider images={images} type={type} works={works} />
    </>
  );
}
