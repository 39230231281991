import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, styled, Typography, useTheme } from "@mui/material";
import { Home } from "@mui/icons-material";
import appInfo from "@data/app-info.json";


const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export function BreadCrumbs() {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((n) => n);  
  const theme = useTheme();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      maxItems={3}
      itemsAfterCollapse={2}
      sx={{ color: theme.palette.grey[500], marginTop: "10px" }}
    >
      {
        pathnames.length > 0 ? (
          <StyledLink to="/">
            <Home />
          </StyledLink>
        ) : null
      }
      {pathnames.map((name, index) => {
        const isCurrent = index === pathnames.length - 1;
        const title = appInfo.menuItems.find(
          (item) => item.path === `/${name}`
        );
        const servicesMenuItem = appInfo.menuItems[1].subMenu.find(
          (item) => item.id === parseInt(name)
        );  

        return isCurrent ? (
          <Typography
            key={name}
            variant="body1"
            sx={{ color: theme.palette.grey[400] }}
          >
            {pathnames.length === 2 ? servicesMenuItem?.title : title?.title}
          </Typography>
        ) : (
          <StyledLink key={name} to={`/${name}`}>
            {title?.title}
          </StyledLink>
        );
      })}
    </Breadcrumbs>
  );
}
