import { combineReducers } from 'redux';
import { popupModalToggleReducer} from "@features/popup";
import { quizModalToggleReducer } from "@components/quiz";
import { lightBoxReducer } from "@components/lightbox";
import { ServicesReducer } from '@features/services/models';

export const appReducers = combineReducers({
  popup: popupModalToggleReducer,
  quiz: quizModalToggleReducer,
  lightbox: lightBoxReducer,
  services: ServicesReducer,
});
