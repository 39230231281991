import React from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Button,
  Grid,
  Link,
  Slide,
  styled,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { HeaderMenu } from "./header-menu/HeaderMenu";
import logo from "src/logo512.png";
import { useDispatch, useSelector } from "react-redux";
import { quizModalToggleAction } from "@components/quiz";
import { popupModalToggleAction } from "@features/popup";
import appInfo from "@data/app-info.json";

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  flexDirection: "row",
  backgroundColor: "#ffffff",
  color: "transparent",
  zIndex: 999,
  "@media all": {},
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media all": {
    maxWidth: 1152,
    width: "100%",
    margin: "0 auto",
    minHeight: 0,
  },
}));

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function Header() {
  const open = useSelector((state: ApplicationState) => state.reducers.popup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    toolBarInner: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 1200,
      width: "100%",
      margin: "0 auto",
      minHeight: 0,
    },
    logoBox: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      maxWidth: { xs: "75px", sm: "100px" },
      width: "100%",
      height: "100%",
      marginTop: "5px",
      objectFit: "contain",
    },
    logoLink: {
      display: "flex",
    },
    logo: {
      width: "100%",
      pointerEvents: "none",
    },
    phoneBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    phoneLink: {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.primary.main,
      textDecoration: "none",
      padding: "0 8px",
      fontSize: {
        xs: "0.8rem",
        sm: "1rem",
        md: "1.2rem",
      },
      fontWeight: 700,
    },
    phoneBtn: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontSize: { xs: "0.7rem", sm: "1rem" },
      margin: "0 auto",
    },
    workTime: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: { xs: "8px", sm: "12px" },
      color: theme.palette.primary.contrastText,
    },
    greenDot: {
      display: "flex",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#25d266",
      marginRight: "8px",
      animation: "glowing 1s infinite",
    },
  };

  const isQuizToggle = () => {
    dispatch(quizModalToggleAction(false));
    dispatch(popupModalToggleAction(!open));
  };

  return (
    <HideOnScroll>
      <StyledAppbar>
        <StyledToolbar>
          <Grid sx={styles.toolBarInner}>
            <Grid sx={styles.logoBox}>
              <NavLink to="/" style={styles.logoLink}>
                <Box component="img" src={logo} alt="logo" sx={styles.logo} />
              </NavLink>
            </Grid>
            <Grid sx={styles.phoneBox}>
              <Link
                type="tel"
                href={`tel:${appInfo.contacts.messengers[0].phone}`}
                sx={styles.phoneLink}
              >
                {appInfo.contacts.messengers[0].title}
              </Link>
              <Button onClick={isQuizToggle} sx={styles.phoneBtn}>
                Заказать звонок
              </Button>
            </Grid>
            <Grid alignItems={"center"}>
              <HeaderMenu />
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppbar>
    </HideOnScroll>
  );
}
