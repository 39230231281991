import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "@components/layout";
import { HomePage } from "@pages/HomePage";
import { ServicesPage } from "@pages/ServicesPage";
import { PricePage } from "@pages/PricePage";
import { ConstructionPage } from "@pages/ConstructionPage";
import { InteriorPage } from "@pages/InteriorPage";
import { ElectricalPage } from "@pages/ElectricalPage";
import { ContactsPage } from "@pages/ContactsPage";
import { NotFoundPage } from "@pages/NotFoundPage";
import { PoolPage } from "@pages/PoolPage";
import { LandscapingPage } from "@pages/LandscapingPage";
import { PlumbingPage } from "@pages/PlumbingPage";
import { SmartHomePage } from "@pages/SmartHomePage";
import { VentilationPage } from "@pages/VentilationPage";
import { BathhousePage } from "@pages/BathhousePage";
import "./App.css";

export function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="services/construction" element={<ConstructionPage />} />
          <Route path="services/interior" element={<InteriorPage />} />
          <Route path="services/smarthome" element={<SmartHomePage />} />
          <Route path="services/landscaping" element={<LandscapingPage />} />
          <Route path="services/pool" element={<PoolPage />} />
          <Route path="services/bathhouse" element={<BathhousePage />} />
          <Route path="services/plumbing" element={<PlumbingPage />} />
          <Route path="services/electrical" element={<ElectricalPage />} />
          <Route path="services/price" element={<PricePage />} />
          <Route path="services/ventilation" element={<VentilationPage />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="/not-found" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
}
